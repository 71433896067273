


@import './commen.scss';
@import './module.scss';
@import './antd.scss';



//操作按钮margin-right
#maincontent-routerView #pageContentSub #tableForward {
    padding-bottom: 10px;
    button{
        margin-right: 10px;
        margin-top: 10px;
    }
}