.bfb-textInput {
  display: inline-block;
  &.required {
    //红星
    .labelWrapper {
      position: relative;
      .defaultLabel::before {
        display: block;
      }
    }
  }

  &.validateResult-false {
    //验证
    .inputWrapper {
      input {
        border-color: #e02020;
      }
      .validateMsg {
        transform: translateY(100%);
        color: #e02020;
      }
    }
  }
  .labelWrapper {
    display: inline-block;
    width: 48px;
    line-height: 12px;
    text-align: right;
    font-size: 12px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    vertical-align: middle;
    color: rgba(109, 114, 120, 1);
    .defaultLabel {
      display: inline-block;
      position: relative;
      &::before {
        content: "*";
        position: absolute;
        display: none;
        left: -9px;
        top: 0;
        color: #e02020;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 1;
      }
    }
  }
  .inputWrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    overflow: visible;
    input {
      display: inline-block;
      // width: 188px;
      width: 100%;
      height: 40px;
      transition: all 0.3s;
      z-index: 2;
      color: #1C2434;
    //   border-color: green;
      &.clickInput{
        cursor: pointer;
      }
    }
    .validateMsg {
      padding-top: 2px;
      position: absolute;
      z-index: 1;
      left: 0;
      font-size: 12px;
      line-height: 12px;
      left: 0;
      bottom: 0;
      color: transparent;
      transform: translateY(0);
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.searchInput{
  :global(.anticon) {
    background: url(../../../assets/icon/search.png) no-repeat;
    background-size: cover;
  }
  :global(.anticon svg){
    opacity: 0;
    width: 18px;
    height: 18px;
  }
}