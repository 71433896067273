.userArea{
    img{
        width: 16px;
        height: 16px;
        margin-right: 12px;

    }
    span{
        color: var(--c-black2);
        font-weight:500;
    }
}


