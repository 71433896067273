body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}






/* 全局样式 */
:root{
  --fz1: 20px;
  --fz2: 14px;
  --fz3: 12px;

  --c-blue1:#0066FF;
  --bc-bule2:#008EDD;
  --c-white1:#F0F6FF;
  --c-black1:#1C2434;
  --c-black2:#0A1428;
  --c-grey1:#6D7278;
  --c-grey2:#999999;
  --c-grey3:#E5E5E5;
  --c-grey4:#F5F5F5;

  --ff1: PingFang-SC-Bold,PingFang-SC;
  --ff2: PingFang-SC-Medium,PingFang-SC;

  --bc-blue1:#0066FF;
  --bc-bule2:#008EDD;
  --bc-white1:#F0F6FF;
  --bc-black1:#1C2434;
  --bc-black2:#0A1428;
  --bc-grey1:#6D7278;
  --bc-grey2:#999999;
  --bc-grey3:#E5E5E5;
  --bc-grey4:#F5F5F5;
}
body,html{
  min-width: 768px;
  font-size: var(--fz2) !important;
  font-family: var(--ff2) !important
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dcdfe6;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
} */

/* 1行超出点点点 */

.overflow_1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* 2行超出点点点 */

.overflow_2 {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* 3行超出点点点 */

.overflow_3 {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
