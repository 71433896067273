* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

// author: xuyunhua
// last-modify-time: 20200310
// basic ui-component structure.
.basicComponent{
  display: inline-block;
  .basicComponent-text{
    width: 48px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: rgba(109, 114, 120, 1);
    box-sizing: border-box; 
    // white-space: nowrap;
    text-align: right;
    vertical-align: middle;
    line-height: 12px;
  }
  .required-red {
    .labelDefault{
      position: relative;
      line-height: 12px;
      display: inline-block;
      &::before{
        position: absolute;
        top: 0;
        left: -9px;
        content: "*";
        font-weight: bold;
        color: rgba(224, 32, 32, 1);
      }
    }
  }
  .basicComponent-form{
    flex: 1;
    text-align: left;
    margin-left: 10px;
    display: inline-block;
    
  }
  .basicComponent-radio{
    width: 188px;
  }
}
.Radio-labelNext {
  height: 40px;
  line-height: 40px;
  .ant-checkbox-inner {
    background: url(../icon/radio-normal.png) no-repeat center center;
    border: 0;
    background-size: contain;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 0;
  }
  .ant-checkbox-checked::after,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }
  .ant-checkbox + span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(28, 36, 52, 1);
    line-height: 12px;
  }
  .ant-checkbox .ant-checkbox-inner {
    background-color: transparent !important;
    background: url(../icon/radio-normal.png) no-repeat center center;
    background-size: contain;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    background: url(../icon/radio-checked.png) no-repeat center center;
    background-size: contain;
  }
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: transparent !important;
    background: url(../icon/radio-checked-disabled.png) no-repeat center center;
    background-size: contain;
  }
  .ant-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
  }
}
.Checkbox-labelNext{
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #0066FF;
    border-color: #0066FF;
  }
}
.Select-box {
  width: 188px;
  .ant-select-selection {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-select-selection-selected-value {
    font-size: 14px;
    color: #6d7278;
  }
  .ant-select-arrow .ant-select-arrow-icon svg {
    width: 0;
    height: 0;
    // border: 4px solid transparent;
    // border-top: 5px solid #6d7278;
    // border-bottom: 0;
    // position: relative;
    // top: -2px;
  }
}

.RadioButton-listbox {
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    width: 188px;
  }
  .ant-radio-button-wrapper,
  .ant-radio-group-small .ant-radio-button-wrapper
   {
    width: 56px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-weight: 500;
    color: rgba(109, 114, 120, 1);
    text-align: center;
    border: 0;
    margin: 10px 10px 0 0;
    padding: 0 !important;
  }
  .ant-radio-button-wrapper:nth-child(3n) {
    margin-right: 0;
  }
  .ant-radio-button-wrapper:nth-child(1),
  .ant-radio-button-wrapper:nth-child(2),
  .ant-radio-button-wrapper:nth-child(3) {
    margin-top: 0;
  }
  .RadioButton-list{
    height: 26px !important;
    line-height: 26px !important;
    &:hover{
      color: #6D7278;
    }
  }
  .RadioButton-list:nth-child(3n) {
    margin-right: 0;
  }
  .RadioButton-list:nth-child(1),
  .RadioButton-list:nth-child(2),
  .RadioButton-list:nth-child(3) {
    margin-top: 0;
  }
  .ant-radio-button-wrapper-checked {
    background: rgba(0, 102, 255, 0.06);
    color: rgba(0, 102, 255, 1);
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: rgba(0, 102, 255, 0.06);
    color: rgba(0, 102, 255, 1);
    box-shadow: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper:first-child {
    border: 0;
  }
  .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: #fff;
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: none;
  }

  .ant-checkbox-group{
    display: flex;
    flex-wrap: wrap;
    width: 188px;
  }
  .ant-checkbox-wrapper{
    width: 56px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-weight: 500;
    color: rgba(109, 114, 120, 1);
    text-align: center;
    border: 0;
    margin: 10px 10px 0 0;
    padding: 0 !important;
    .ant-checkbox{
      display: none;
    }
  }
  .ant-checkbox-wrapper:nth-child(3n) {
    margin-right: 0;
  }
  .ant-checkbox-wrapper:nth-child(1),
  .ant-checkbox-wrapper:nth-child(2),
  .ant-checkbox-wrapper:nth-child(3) {
    margin-top: 0;
  }
  .checkboxButton-list:nth-child(3n) {
    margin-right: 0;
  }
  .checkboxButton-list:nth-child(1),
  .checkboxButton-list:nth-child(2),
  .checkboxButton-list:nth-child(3) {
    margin-top: 0;
  }
  .ant-checkbox-wrapper-checked {
    background: rgba(0, 102, 255, 0.06);
    color: rgba(0, 102, 255, 1);
  }
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled),
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover {
    background: rgba(0, 102, 255, 0.06);
    color: rgba(0, 102, 255, 1);
    box-shadow: none;
  }
  .ant-checkbox-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-checkbox-wrapper:first-child {
    border: 0;
  }
  .ant-checkbox-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-checkbox-wrapper-disabled:first-child,
  .ant-checkbox-wrapper-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }
  .ant-checkbox + span{
    padding: 0;
  }
  .ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-checked {
    color: #fff;
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-radio-button-wrapper:first-child,
  .ant-radio-button-wrapper:last-child {
    border-radius: 6px;
  }
}
.Tabs-listbox {
  .ant-tabs-bar{
    border-bottom: 0;
    margin: 0;
  }
  .ant-tabs-nav .ant-tabs-tab{
    width:160px;
    height:40px;
    line-height: 40px;
    margin: 0 40px 0 0;
    padding: 0;
    background:rgba(0,0,0,0.05);
    border-radius:6px;
    text-align: center;
    font-size:14px;
    font-weight:500;
    color:rgba(153,153,153,1);
    &:hover{
      color:rgba(0,102,255,1);
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active{
    background:rgba(229,239,255,1);
    color:rgba(0,102,255,1);
  }
  .ant-tabs-ink-bar{
    visibility: hidden;
    opacity: 0;
  }
}
.Switch-labelNext{
  .ant-switch-checked{
    background-color: #0066FF;
  }
}
.carousel-wrapper{
  width: 490px;
  margin: 28px auto 0;
  .ant-carousel .slick-dots{
    height: 8px;
  }
  .ant-carousel .slick-dots li button{
    height: 8px;
    background: #000;
  }
}
// 弹窗
.modal-wrapper-table{
  border:1px solid #e5e5e5;
  border-radius:4px;
  margin-top: 50px;
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  .modal-wrapper-head{
      border:1px solid #e5e5e5;
      background:rgba(249,249,249,1);
      height: 35px;
      line-height: 35px;
      padding: 0 5px;
      font-weight:bold;
      color:#6D7278;
  }
  .modal-wrapper-txt{
      border:1px solid #e5e5e5;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:bold;
      line-height:50px;
      color:#6D7278;
      padding: 0 5px;
  }
}
.pb10{
  padding-bottom: 10px;
}
// 统计报表头部样式
.table-forward-total{
  .table-forward-total-title{
    font-size:20px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(10,20,40,1);
    line-height:18px;
    padding:10px 0 20px;
    text-align: center;
  }
  .table-forward-total-btns{
    padding-bottom: 14px;
  }
  .table-forward-total-tips{
    height:36px;
    line-height:36px;
    background:rgba(250,246,245,1);
    border-radius:6px;
    padding: 0 14px;
    display: flex;
    justify-content: space-between;
    span{
      font-size:14px;
      font-family:PingFang-SC-Medium,PingFang-SC;
      font-weight:500;
      color:rgba(164,125,116,1);
      white-space: nowrap;
    }
  }
}