//抽屉右下角按钮
.drawer-button-div {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  .ant-btn {
    margin-left: 10px;
  }
}
//列表页头部
.form-title-div {
  height: 80px;
  span.form-title {
    display: inline-block;
    height: 100%;
    line-height: 80px;
    font-size: 20px;
    text-indent: 20px;
  }
  div.form-button {
    float: right;
    height: 80px;
    line-height: 80px;
    .ant-btn {
      margin-right: 20px;
    }
  }
}
//
.table-button-span {
  span {
    cursor: pointer;
    color: #1890ff;
  }
}
// @import './antd/timePicker.scss'

.ant-calendar .ant-calendar-ok-btn {
  background: linear-gradient(135deg, #0066ff 0%, #008edd 100%) !important;
  font-weight: bold !important;
  color: #fff !important;
  &:hover {
    background: linear-gradient(135deg, rgba(0, 102, 255, 1) 0%, rgba(0, 102, 255, 1) 0%) !important;
  }
}
